:root {
  --prolech-primary: #125CA5;
}

body {
  font-size: 12px;
}

.modul_menu_gora {
  height: 5px;
  width: 100%;
  background-color: #7FA5C9;
}

.modul_menu_nazwa {
  width: 100%;
  height: 45px;
  font-size: 12px;
  color: #FFFFFF;
  font-family: Tahoma;
  font-style: normal;
  font-weight: bold;
  background-color: var(--prolech-primary);
  padding-left: 10px;
}

.modul_menu_srodek {
  width: 100%;
  background-color: #FFFFFF;
  background-position: top;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.wpis_srodek {
  background-color: #FFFFFF;
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  width: 100%;
}

a:link.modul_menu_poziom1, a:visited.modul_menu_poziom1, a:active.modul_menu_poziom1 {
  color: #333333;
  text-decoration: none;
}

a:link.modul_menu_poziom2, a:visited.modul_menu_poziom2, a:active.modul_menu_poziom2a {
  color: #333333;
  text-decoration: none;
}

a:link.modul_menu_poziom3, a:visited.modul_menu_poziom3, a:active.modul_menu_poziom3 {
  color: #333333;
  text-decoration: none;
}

a:link.modul_menu_poziom1a, a:visited.modul_menu_poziom1a, a:active.modul_menu_poziom1a {
  color: #333333;
  font-weight: normal;
  text-decoration: none;
}

a:link.modul_menu_poziom2a, a:visited.modul_menu_poziom2a, a:active.modul_menu_poziom2a {
  color: #333333;
  font-weight: normal;
  text-decoration: none;
}

a:link.modul_menu_poziom3a, a:visited.modul_menu_poziom3a, a:active.modul_menu_poziom3a {
  color: #333333;
  font-weight: normal;
  text-decoration: none;
}

a:hover.modul_menu_poziom1 {
  color: #333333;
  font-weight: normal;
  text-decoration: underline;
}

a:hover.modul_menu_poziom2 {
  color: #333333;
  font-weight: normal;
  text-decoration: underline;
}

a:hover.modul_menu_poziom3 {
  color: #333333;
  font-weight: normal;
  text-decoration: underline;
}

a:hover.modul_menu_poziom1a {
  color: #333333;
  font-weight: bold;
  text-decoration: underline;
}

a:hover.modul_menu_poziom2a {
  color: #333333;
  font-weight: normal;
  text-decoration: underline;
}

a:hover.modul_menu_poziom3a {
  color: #333333;
  font-weight: normal;
  text-decoration: underline;
}

.modul_menu_poziom1 {
  font-family: tahoma;
  font-size: 12px;
  font-style: normal;
  width: 100%;
}

.modul_menu_poziom1a {
  font-family: tahoma;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  background-color: #EEEEEE;
  width: 100%;
}

.cena_netto {
  color: #333333;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: bolder;
  text-decoration: none;
  line-height: 18px;
}

.niebieski_link_bold, a:link.niebieski_link_bold, a:visited.niebieski_link_bold, a:active.niebieski_link_bold {
  color: #125CA5;
  font-size: 11px;
  font-weight: bold;
  text-decoration: none;
}

a:hover.niebieski_link_bold {
  text-decoration: underline;
}
