.dark {
  body {
    font-size: 12px;
  }

  .modul_menu_gora {
    height: 5px;
    width: 100%;
    background-color: #7FA5C9;
  }

  .modul_menu_nazwa {
    width: 100%;
    height: 45px;
    font-size: 12px;
    color: #FFFFFF;
    font-family: Tahoma;
    font-style: normal;
    font-weight: bold;
    background-color: var(--prolech-primary);
    padding-left: 10px;
  }

  .modul_menu_srodek {
    width: 100%;
    background-color: var(--mdb-table-bg);
    background-position: top;
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }

  .wpis_srodek {
    background-color: var(--mdb-table-bg);
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    width: 100%;
  }
}